<template>
    <div class="block block--incident_detail">
        <div class="incident-head">
            <h1>{{profile.incident_detail.imagery}} & {{profile.incident_detail.documents}}</h1>
        </div>
        <div class="incident-media">
            <label>{{profile.incident_detail.imagery}} <i class="fas fa-info-circle" v-tooltip.right="profile.incident_detail.media_tooltip" style="cursor: pointer;"></i></label>
            <span class="edit-button edit-button--small icon" data-testid="buttonUploadMedia" @click="uploadMediaModalOpen = true;" v-if="!readOnly || (readOnly && incidentInfo.serviced_by_bm_user_id && admin) || (incidentInfo.incident_agreement_flow) && !forwardedReadOnly" style="margin-top: 0;"><i class="fa-solid fa-plus"></i></span>
            <div class="media-wrapper">
                <div class="media media--small" data-testid="showMediaItem" v-for="(item, index) in media" :key="index" :class="{active: item.popover, video: item.content_type === 'video/mp4'}">
                    <div class="copy" v-if="item.description" v-tooltip.right="item.description" @click="copyText(item.description)" style="position: absolute; left: 0; top: 0; background: white; padding: 2px 5px 0 5px; box-shadow: 0px 1px 1px 0px rgba(71, 70, 70, 0.35); z-index: 2;"><i class="fas fa-copy" style="color: #484848; font-size: 14px;"></i></div>
                    <img :src="item.file_url" @click="openPopover(item)" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" />
                    <div class="iframe_overlay" v-if="item.i_frame_code" @click="openPopover(item)" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 20;"></div>
                    <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: -35px;"></div>
                    <div class="video_overlay" @click="openPopover(item)" v-if="item.content_type === 'video/mp4'"><i class="fas fa-play"></i></div>
                    <video v-if="item.content_type === 'video/mp4'" @click="openPopover(item)">
                        <source :src="item.file_url" type="video/mp4">
                    </video>
                    <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                    <span class="remove" data-testid="removeMediaItem" v-if="(item.type === 'incident_building_manager' || admin) && !readOnly" @click="selectFileToRemove(item)"><i class="far fa-trash-alt"></i></span>
                    <div class="media-pop-over">
                        <span class="close-popover" @click="closePopover()"><i class="fa fa-times"></i></span>
                        <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                        <span class="media-counter" style="right:0; top: 30px;">{{index + 1}} / {{media.length}}</span>
                        <img data-testid="mediaImageOverlay" :src="item.file_url" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" :class="{rotate_2: item.rotate === 2, rotate_3: item.rotate === 3, rotate_4: item.rotate === 4}" />
                        <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: 40px;"></div>
                        <video data-testid="mediaVideoOverlay" v-if="item.content_type === 'video/mp4'" :class="{rotated: item.rotate}" controls><source :src="item.file_url" type="video/mp4"></video>
                        <p v-if="item.description">{{item.description}}</p>
                        <span class="media-control left" v-if="index !== 0" @click="selectMedia('prev', index)"><i class="fa fa-chevron-left"></i></span>
                        <span class="media-control right" v-if="index + 1 !== media.length" @click="selectMedia('next', index)"><i class="fa fa-chevron-right"></i></span>
                    </div>
                    <div class="timestamp">{{ item.date }}</div>
                </div>
                <div class="media video" data-testid="showMediaVideo" v-for="item in movs" :key="item.id_file_upload">
                    <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                    <span class="placeholder"><i class="fas fa-file-video"></i></span>
                    <a :href="item.file_url" class="video_overlay download" v-if="item.content_type === 'video/quicktime'"></a>
                    <div class="timestamp">{{ item.date }}</div>
                    <span class="remove" data-testid="buttonRemoveVideo" v-if="(item.type === 'incident_building_manager' || admin) && !readOnly" @click="selectFileToRemove(item)"><i class="far fa-trash-alt"></i></span>
                </div>
            </div>
        </div>
        <div class="incident-docs">
            <label>{{profile.incident_detail.documents}} <i class="fas fa-info-circle" v-tooltip.right="profile.incident_detail.docs_tooltip" style="cursor: pointer;"></i></label>
            <span class="edit-button edit-button--small icon" data-testid="buttonUploadDocument" @click="uploadMediaModalOpen = true;" v-if="!readOnly || (readOnly && incidentInfo.serviced_by_bm_user_id && admin) || (incidentInfo.incident_agreement_flow) && !forwardedReadOnly" style="margin-top: 0;"><i class="fa-solid fa-plus"></i></span>
            <ul v-if="docs.length">
                <li v-for="(item, index) in docs" :key="index">
                    <div class="timestamp">{{ item.date }}</div>
                    <a :href="item.file_url" target="blank" data-testid="showDocument" v-if="item.description"><i class="fas fa-file-pdf"></i>{{item.description}}</a>
                    <a :href="item.file_url" target="blank" data-testid="showDocument"  v-else><i class="fas fa-file-pdf"></i>{{item.filename}}</a>
                    <span class="remove" data-testid="buttonRemoveDocument" v-if="(item.type === 'incident_building_manager' || admin) && !readOnly" @click="selectFileToRemove(item)"><i class="far fa-trash-alt"></i></span>
                </li>
            </ul>
        </div>
        <UploadMediaModal v-if="uploadMediaModalOpen" @close="uploadMediaModalOpen = false" @success="getIncidentInfo()" :incidentId="incidentInfo.id_incident" :incidentInfo="incidentInfo" />
        <RemoveFileModal v-if="removeFileModalOpen" @close="removeFileModalOpen = false" @success="getIncidentInfo()" :file="file" :incidentId="incidentInfo.id_incident" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import UploadMediaModal from "@/components/modals/UploadMediaModal.vue";
import RemoveFileModal from "@/components/modals/RemoveFileModal.vue";

export default {
    name: 'MediaDocs',
    mixins: [setLocale],
    components: {
        UploadMediaModal,
        RemoveFileModal,
    },
    data: function () {
        return {
            uploadMediaModalOpen: false,
            removeFileModalOpen: false
        }
    },
    props: ['incidentId', 'incidentInfo', 'media', 'movs', 'docs', 'mediacount', 'readOnly', 'admin', 'forwardedReadOnly'],
    methods: {
        selectMedia(type, index) {
            if (type === 'prev') {
                index = index - 1;
                for (var i = 0; i < this.media.length; i++) {
                    this.media[i].popover = false;
                    this.media[index].popover = true;
                }
            }
            if (type === 'next') {
                index = index + 1;
                for (var j = 0; j < this.media.length; j++) {
                    this.media[j].popover = false;
                    this.media[index].popover = true;
                }
            }
        },
        openPopover(item) {
            item.popover = true;
            document.querySelector('body').classList.add('media-open');
        },
        closePopover() {
            document.querySelector('body').classList.remove('media-open');
            for (var i = 0; i < this.incidentInfo.pictures.length; i++) {
                this.incidentInfo.pictures[i].popover = false;
            }
        },
        selectFileToRemove(item) {
            this.removeFileModalOpen = true;
            this.file = item;
        },
        rotateMedia(item) {
            item.rotate++;
            if (item.rotate === 5) {
                item.rotate = 1;
            }
        },
        getIncidentInfo() {
            this.removeFileModalOpen = false;
            this.$emit('getIncidentInfo');
        },
        copyText(text) {
            if (text) {
                navigator.clipboard.writeText(text);
                this.$vToastify.success(this.profile.incident_detail.text_copied, this.profile.toast.changes_saved);
            }
        }
    }
}
</script>
