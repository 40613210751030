<template>
    <div class="block block--account block--border" v-if="profile.accountPage.account.wa_msg_title" style="margin-bottom: 0;">
        <div class="account-info">
            <h4 style="margin-top: 4px; margin-bottom: 0;">{{profile.accountPage.account.standard_wa_messages}}</h4> 
            <span class="edit-button icon" data-testid="messageAddButton" style="margin-top: 10px;" @click="newMessageModalOpen = true" v-if="accountInfo.building_manager_admin"><i class="fa-solid fa-plus"></i></span>
        </div>
        <div style="margin-top: 15px;">
            <div v-for="message in defaultMessages" :key="message.id_predefined_wa_message" data-testid="messageEditButton" class="mandate" @click="message.edit = true">
                <span class="edit-button edit-button--small" v-if="!message.edit && accountInfo.building_manager_admin" style="margin-right: 15px; margin-top: 10px;"><i class="fas fa-pen"></i></span>
                <span class="edit-button edit-button--small" data-testid="messageSaveButton" @click="saveDefaultMessage(message)" v-if="message.edit" style="margin-right: 15px; margin-top: 10px;"><i class="fas fa-save"></i></span>
                <span class="edit-button edit-button--small" data-testid="messageDeleteButton" @click="deleteDefaultMessage(message)" v-if="!message.edit && accountInfo.building_manager_admin" style="margin-right: 40px; margin-top: 10px;"><i class="far fa-trash-alt"></i></span>
                <div v-if="!message.edit">
                    <h6 style="margin-bottom: 10px;">{{message.title}}</h6>
                    <p>{{ message.message }}</p>
                </div>
                <div v-else style="padding-top: 15px;">
                    <div class="row">
                        <div class="col">
                            <label>{{profile.accountPage.account.wa_msg_title}}</label>
                            <input type="text" data-testid="messageTitleEdit" ref="title" class="form-control" v-model="message.title" @input="checkTitle()" style="margin-bottom: 15px;" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>{{profile.accountPage.account.message}}</label>
                            <textarea class="form-control" data-testid="messageTextEdit" v-model="message.message" @input="checkMessage(message)" ></textarea>
                        </div>
                    </div>
                    <div class="errors" v-if="titleError && messageError">
                        <span class="notification notification--error" data-testid="messageTitleError" v-if="titleError">{{profile.mandates.name_error}}</span>
                        <span class="notification notification--error" data-testid="messageTextError" v-if="messageError">{{profile.mandates.amount_error}}</span>
                    </div>
                </div>
            </div>
        </div> 
        <NewMessageModal v-if="newMessageModalOpen" @close="newMessageModalOpen = false" @created="getWaMessages(message)" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import NewMessageModal from "@/components/modals/NewMessageModal.vue";
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    components: {
        NewMessageModal
    },
    data: function () {
        return {
            newMessageModalOpen: false,
            defaultMessages: [],
            titleError: false,
            messageError: false
        }
    },
    props: ['accountInfo'],
    created() {
        this.getWaMessages();
    },
    methods: {
        getWaMessages() {
            this.defaultMessages = [];
            this.newMessageModalOpen = false;
            let self = this;
            fetch('/api/v1/predefinedwamessage/bybuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    if (data.length) {
                        for (var i = 0; i < data.length; i++) {
                            let message = data[i];
                            message.edit = false;
                            self.defaultMessages.push(message);
                        }
                    }
                    self.defaultMessages = sortBy(self.defaultMessages, 'title');
                });
            });
        },
        saveDefaultMessage(message) {
            if (!message.title.length) {
                this.titleError = true;
            }
            else {
                this.titleError = false;
            }
            if (!message.message.length) {
                this.messageError = true;
            }
            else {
                this.messageError = false;
            }
            if (!this.titleError && !this.messageError) {
                message.edit = false;
                const params = new URLSearchParams();
                params.append('title', message.title);
                params.append('message', message.message);
                params.append('id_predefined_wa_message', message.id_predefined_wa_message );
                let self = this;
                fetch('/api/v1/predefinedwamessage/update', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getWaMessages();
                });
            }
        },
        deleteDefaultMessage(message) {
            const data = new URLSearchParams();
            data.append('id_predefined_wa_message', message.id_predefined_wa_message );
            let self = this;
            fetch('/api/v1/predefinedwamessage/delete', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getWaMessages();
            });
        },
        checkTitle(message) {
            if (this.message.title) {
                this.titleError = false;
            }
            else {
                this.titleError = true;
            }
        },
        checkMessage(message) {
            if (this.message.message) {
                this.messageError = false;
            }
            else {
                this.messageError = true;
            }
        }
    }
}
</script>
