<template>
    <div class="block block--account block--border">
        <div class="account-info">
            <h4 style="margin-bottom: 0;">{{profile.accountPage.company.my_company}}</h4>
            <div class="account-contents">
                <div class="info-row alt-hover" data-testid="companyNameEditButton" @click="nameEdit = true">
                    <label>{{profile.accountPage.account.contactname}}
                        <span class="edit-button edit-button--small" v-if="!nameEdit"><i class="fas fa-pencil-alt"></i></span>
                        <span class="edit-button edit-button--small" data-testid="companyNameSave" @click="saveName()" v-if="nameEdit"><i class="far fa-save"></i></span>
                    </label>
                    <div v-if="nameEdit">
                        <input type="text" class="form-control" v-model="accountInfo.building_manager_contact_name" data-testid="companyInputName" />
                    </div>
                    <div v-else>
                        <p data-testid="managerNameText">{{accountInfo.building_manager_contact_name}}</p>
                    </div>
                </div>
                <div class="info-row alt-hover" data-testid="companyMobileEditButton" @click="mobileEdit = true">
                    <label>{{profile.accountPage.account.contactmobile}}
                        <span class="edit-button edit-button--small" v-if="!mobileEdit"><i class="fas fa-pencil-alt"></i></span>
                        <span class="edit-button edit-button--small" data-testid="companyMobileSave" @click="saveMobile()" v-if="mobileEdit"><i class="far fa-save"></i></span>
                    </label>
                    <div v-if="mobileEdit">
                        <input type="text" class="form-control" v-model="accountInfo.building_manager_mobile" @input="validateMobilePhone()" :class="{valid: validMobilePhone, error: invalidMobilePhone}" data-testid="companyInputMobile" />
                    </div>
                    <div v-else>
                        <p data-testid="managerMobileText">{{accountInfo.building_manager_mobile}}</p>
                    </div>
                </div>
                <div class="info-row alt-hover" data-testid="companyPhoneEditButton" @click="phoneEdit = true">
                    <label>{{profile.accountPage.account.contactphone}}
                        <span class="edit-button edit-button--small" v-if="!phoneEdit"><i class="fas fa-pencil-alt"></i></span>
                        <span class="edit-button edit-button--small" data-testid="companyPhoneSave" @click="savePhone()" v-if="phoneEdit"><i class="far fa-save"></i></span>
                    </label>
                    <div v-if="phoneEdit">
                        <input type="text" class="form-control" v-model="accountInfo.building_manager_phone" @input="validateGeneralPhone()" :class="{valid: validGeneralPhone, error: invalidGeneralPhone}" data-testid="companyInputPhone" />
                    </div>
                    <div v-else>
                        <p data-testid="managerPhoneText">{{accountInfo.building_manager_phone}}</p>
                    </div>
                </div>
                <div class="info-row alt-hover" data-testid="companyOooEditButton" @click="oooEdit = true">
                    <label>{{profile.accountPage.account.ooo_phone}} <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.no_ooo" style="cursor: pointer;"></i>
                        <span class="edit-button edit-button--small" v-if="!oooEdit"><i class="fas fa-pencil-alt"></i></span>
                        <span class="edit-button edit-button--small" data-testid="companyOooSave" @click="saveOoo()" v-if="oooEdit"><i class="far fa-save"></i></span>
                    </label>
                    <div v-if="oooEdit">
                        <input type="text" class="form-control" v-model="accountInfo.ooophone" @input="validateOooPhone()" :class="{valid: validOooPhone, error: invalidOooPhone}" data-testid="companyInputExtraPhone" />
                    </div>
                    <div v-else>
                        <p data-testid="managerOutsidePhoneText">{{accountInfo.ooophone}}</p>
                    </div>
                </div>
                <div class="info-row alt-hover" data-testid="companyEmailEditButton" @click="emailEdit = true">
                    <label>{{profile.accountPage.account.correspondence_email}}  <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.correspondence_tooltip" style="cursor: pointer;"></i>
                        <span class="edit-button edit-button--small" v-if="!emailEdit"><i class="fas fa-pencil-alt"></i></span>
                        <span class="edit-button edit-button--small" data-testid="companyEmailSave" @click="saveEmail()" v-if="emailEdit"><i class="far fa-save"></i></span>
                    </label>
                    <div v-if="emailEdit">
                        <input type="text" class="form-control" v-model="accountInfo.building_manager_correspondence_email" @input="validateCorrEmail()" :class="{valid: validCorrEmail, error: invalidCorrEmail}" data-testid="companyInputEmail" />
                    </div>
                    <div v-else>
                        <p data-testid="managerCorrespondentText" v-if="accountInfo.building_manager_correspondence_email !== 'null'">{{accountInfo.building_manager_correspondence_email}}</p>
                    </div>
                </div>
                <div class="info-row alt-hover" data-testid="companyContactEditButton" @click="contactEmailEdit = true">
                    <label>{{profile.accountPage.account.contactemail}}
                        <span class="edit-button edit-button--small" v-if="!contactEmailEdit"><i class="fas fa-pencil-alt"></i></span>
                        <span class="edit-button edit-button--small" data-testid="companyContactSave" @click="saveContactEmail()" v-if="contactEmailEdit"><i class="far fa-save"></i></span>
                    </label>
                    <div v-if="contactEmailEdit">
                        <input type="text" class="form-control" v-model="accountInfo.building_manager_email" @input="validateEmail()" :class="{valid: validEmail, error: invalidEmail}" data-testid="companyInputContact" />
                    </div>
                    <div v-else>
                        <p data-testid="managerEmailText">{{accountInfo.building_manager_email}}</p>
                    </div>
                </div>
                <div class="info-row alt-hover last" data-testid="companyGlnEditButton" @click="glnEdit = true" v-if="vve">
                    <label>GLN
                        <span class="edit-button edit-button--small" v-if="!glnEdit"><i class="fas fa-pencil-alt"></i></span>
                        <span class="edit-button edit-button--small" data-testid="companyGlnEdit" @click.stop="saveGln()" v-if="glnEdit"><i class="far fa-save"></i></span>
                    </label>
                    <div v-if="glnEdit">
                        <input type="text" class="form-control" v-model="accountInfo.gln" maxlength="20" data-testid="companyInputGln"/>
                    </div>
                    <div v-else>
                        <p data-testid="companyGlnText">{{accountInfo.gln}}</p>
                    </div>
                </div>
            </div>
            <hr style="margin: 0 -20px;" />
            <div class="info-row alt-hover" data-testid="companyCorrEmailEditButton" @click="emailsEdit = true">
                <label>{{profile.repair_companies.repaircompanytable.corrEmail}}
                    <span class="edit-button edit-button--small" v-if="!emailsEdit"><i class="fas fa-pencil-alt"></i></span>
                    <span class="edit-button edit-button--small" data-testid="companyCorrEmailSave" @click="saveEmails()" v-if="emailsEdit"><i class="far fa-save"></i></span>
                </label>
                <div v-if="emailsEdit">
                    <input type="text" class="form-control" v-model="accountInfo.building_manager_email_addresses" data-testid="companyInputReceiver" />
                </div>
                <div v-else>
                    <p data-testid="managerNewEmailText">{{accountInfo.building_manager_email_addresses}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="switch-wrapper switch-wrapper--full no-hover" v-if="accountInfo.building_manager_admin" :class="{disabled: !accountInfo.building_manager_email_addresses, active: accountInfo.send_incident_new}">
                        <div class="inner" @click="toggleSendMailNewIncident()">
                            <i class="fa-regular fa-paper-plane"></i>                            
                            <p>{{profile.employees.send_new_incident}} <i class="fas fa-info-circle" v-tooltip.right="profile.employees.send_new_incident_tooltip" style="cursor: pointer;"></i></p>
                            <span class="label">{{profile.switches.send_automatic_mail}}</span>
                            <label class="switch">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="switch-wrapper switch-wrapper--full no-hover" v-if="accountInfo.building_manager_admin" :class="{disabled: !accountInfo.building_manager_email_addresses, active: accountInfo.send_incident_closed}">
                        <div class="inner" @click="toggleSendMailClose()">
                            <i class="fa-regular fa-paper-plane"></i>                            
                            <p>{{profile.employees.send_on_close}} <i class="fas fa-info-circle" v-tooltip.right="profile.employees.send_on_close_tooltip" style="cursor: pointer;"></i></p>
                            <span class="label">{{profile.switches.send_automatic_mail_closed}}</span>
                            <label class="switch">
                            <span class="slider round"></span>
                        </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    name: 'CompanyBlock',
    mixins: [setLocale],
    data: function () {
        return {
            nameEdit: false,
            mobileEdit: false,
            phoneEdit: false,
            oooEdit: false,
            emailEdit: false,
            glnEdit: false,
            contactEmailEdit: false,   
            emailsEdit: false,
            validGeneralPhone: false,
            invalidGeneralPhone: false,
            validMobilePhone: false,
            invalidMobilePhone: false,
            validOooPhone: false,
            invalidOooPhone: false,
            validCorrEmail: false,
            invalidCorrEmail: false,
            validEmail: false,
            invalidEmail: false,
            validGLN: false
        }
    },
    props: ['accountInfo', 'vve'],
    methods: {
        saveName() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][contact_name]', this.accountInfo.building_manager_contact_name);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.nameEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveMobile() {
            if (!this.invalidMobilePhone) {
                const params = new URLSearchParams();
                params.append('action', 'edit');
                params.append('data[0][mobile]', this.accountInfo.building_manager_mobile);
                let self = this;
                fetch('/api/v1/buildingmanager', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.mobileEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        savePhone() {
            if (!this.invalidGeneralPhone) {
                const params = new URLSearchParams();
                params.append('action', 'edit');
                params.append('data[0][phone]', this.accountInfo.building_manager_phone);
                let self = this;
                fetch('/api/v1/buildingmanager', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.phoneEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        saveOoo() {
            if (!this.invalidOooPhone) {
                const params = new URLSearchParams();
                params.append('action', 'edit');
                params.append('data[0][ooophone]', this.accountInfo.ooophone);
                let self = this;
                fetch('/api/v1/buildingmanager', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.oooEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }      
        },
        saveEmail() {
            if (!this.invalidCorrEmail) {
                const params = new URLSearchParams();
                params.append('action', 'edit');
                params.append('data[0][email]', this.accountInfo.building_manager_email);
                params.append('data[0][correspondence_email]', this.accountInfo.building_manager_correspondence_email);
                let self = this;
                if (!this.invalidEmail && !this.invalidCorrEmail) {
                    fetch('/api/v1/buildingmanager', {
                        method: 'post',
                        body: params
                    }).then((resp) => {return resp.text();
                    }).then(function(data) {
                        self.emailEdit = false;
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    });
                }
            }
        },
        saveContactEmail() {
            if (!this.invalidEmail) {
                const params = new URLSearchParams();
                params.append('action', 'edit');
                params.append('data[0][email_addresses]', this.accountInfo.building_manager_email_addresses);
                let self = this;
                fetch('/api/v1/buildingmanager', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.contactEmailEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        saveGln() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][gln]', this.accountInfo.gln);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.glnEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveEmails() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][email_addresses]', this.accountInfo.building_manager_email_addresses);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.emailsEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        validateCorrEmail() {
            if (this.accountInfo.building_manager_correspondence_email) {
                let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (pattern.test(this.accountInfo.building_manager_correspondence_email)) {
                    this.validCorrEmail = true;
                    this.invalidCorrEmail = false;
                }
                else {
                    this.invalidCorrEmail = true;
                    this.validCorrEmail = false;
                }
            }
            else {
                this.invalidCorrEmail = false;
                this.validCorrEmail = false;
            }
        },
        validateEmail() {
            if (this.accountInfo.building_manager_email) {
                let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (pattern.test(this.accountInfo.building_manager_email)) {
                    this.validEmail = true;
                    this.invalidEmail = false;
                }
                else {
                    this.invalidEmail = true;
                    this.validEmail = false;
                }
            }
            else {
                this.invalidEmail = false;
                this.validEmail = false;
            }
        },
        validateGeneralPhone() {
            if (this.accountInfo.building_manager_phone) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.building_manager_phone)) {
                    this.validGeneralPhone = true;
                    this.invalidGeneralPhone = false;
                }
                else {
                    this.invalidGeneralPhone = true;
                    this.validGeneralPhone = false;
                }
            }
            else {
                this.invalidGeneralPhone = false;
                this.validGeneralPhone = false;
            }
        },
        validateMobilePhone() {
            if (this.accountInfo.building_manager_mobile) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.building_manager_mobile)) {
                    this.validMobilePhone = true;
                    this.invalidMobilePhone = false;
                }
                else {
                    this.invalidMobilePhone = true;
                    this.validMobilePhone = false;
                }
            }
            else {
                this.invalidMobilePhone = false;
                this.validMobilePhone = false;
            }
        },
        validateOooPhone() {
            if (this.accountInfo.ooophone) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.ooophone)) {
                    this.validOooPhone = true;
                    this.invalidOooPhone = false;
                }
                else {
                    this.invalidOooPhone = true;
                    this.validOooPhone = false;
                }
            }
            else {
                this.invalidOooPhone = false;
                this.validOooPhone = false;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        toggleSendMailClose() {
            this.accountInfo.send_incident_closed = !this.accountInfo.send_incident_closed;
            const params = new URLSearchParams();
            params.append('data[0][send_incident_closed]', this.accountInfo.send_incident_closed);
            params.append('action', 'edit');
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        toggleSendMailNewIncident() {
        this.accountInfo.send_incident_new = !this.accountInfo.send_incident_new;
        const params = new URLSearchParams();
        params.append('data[0][send_incident_new]', this.accountInfo.send_incident_new);
        params.append('action', 'edit');
        let self = this;
        fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
