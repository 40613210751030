<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners" >
                            <section class="incident-head">
                                <h1>Teksten</h1>
                                <span class="edit-button icon" @click="newTextModalOpen = true"><i class="fa-solid fa-plus"></i></span>
                            </section>
                            <div class="row">
                                <div class="col-sm-8">
                                    <section class="search search-alt" v-if="texts.length">
                                        <form @submit.prevent="searchSuggest()">
                                            <input type="text" ref="search" v-bind:placeholder="'Zoek een tekst'" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" />
                                            <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                        </form>
                                    </section>
                                </div>
                            </div>
                            <div>
                                <h4 class="result-counter" v-if="!searchActive">{{texts.length}} {{'teksten'}}</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length === 1">{{searchSuggestions.length}} {{'resultaat gevonden met de term'}} '{{searchTerm}}'</h4>
                                <h4 class="result-counter" v-if="!searchSuggestions.length && searchActive">{{"Geen resultaten gevonden met de term"}} '{{searchTerm}}'.</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length > 1">{{searchSuggestions.length}} {{'resultaten gevonden met de term'}} '{{searchTerm}}'</h4>
                            </div>
                            <div class="table-wrap" style="height: 600px; overflow: auto;">
                                <table class="table table-striped" v-if="texts.length && !searchActive">
                                    <colgroup>
                                        <col style="width: 28%;" />
                                        <col style="width: 8%;" />
                                        <col style="width: 23%;" />
                                        <col style="width: 23%;" />
                                        <col style="width: 10%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th scope="col" @click="sortTable('name')" :class="{active: textSearchType === 'name'}">Technische Naam<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('description')" :class="{active: textSearchType === 'description'}">Omschrijving <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('nl')" :class="{active: textSearchType === 'nl'}">Nederlandse text<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('en')" :class="{active: textSearchType === 'en'}">Engelse text<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('en')" :class="{active: textSearchType === 'en'}">Branch<i class="fas fa-sort"></i></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(text, index) in textsShown" :key="index">
                                            <td>{{text.name}}</td>
                                            <td>{{text.description}}</td>
                                            <td>{{text.nl}}</td>
                                            <td>{{text.en}}</td>
                                            <td>{{text.industry_constant}}</td>
                                            <td><i class="fas fa-pen" @click="EditTextModalOpen = true; editText=text"></i></td>
                                            <td><i class="far fa-trash-alt" @click="RemoveTextModalOpen = true; removeText=text"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-striped" v-if="texts.length && searchActive">
                                    <colgroup>
                                        <col style="width: 28%;" />
                                        <col style="width: 8%;" />
                                        <col style="width: 23%;" />
                                        <col style="width: 23%;" />
                                        <col style="width: 10%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th scope="col" @click="sortTable('name')" :class="{active: textSearchType === 'name'}">Technische Naam<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('description')" :class="{active: textSearchType === 'description'}">Omschrijving <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('nl')" :class="{active: textSearchType === 'nl'}">Nederlandse text<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('en')" :class="{active: textSearchType === 'en'}">Engelse text<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('en')" :class="{active: textSearchType === 'en'}">Branch<i class="fas fa-sort"></i></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(text, index) in searchSuggestions" :key="index">
                                            <td>{{text.name}}</td>
                                            <td>{{text.description}}</td>
                                            <td>{{text.nl}}</td>
                                            <td>{{text.en}}</td>
                                            <td>{{text.industry_constant}}</td>
                                            <td><i class="fas fa-pen" @click="EditTextModalOpen = true; editText=text"></i></td>
                                            <td><i class="far fa-trash-alt" @click="RemoveTextModalOpen = true; removeText=text"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        <NewTextModal v-if="newTextModalOpen" @close="loadTexts()" />  
        <RemoveTextModal v-if="RemoveTextModalOpen" :texts="removeText" @close="loadTexts()" />
        <EditTextModal v-if="EditTextModalOpen" :text="editText" @close="loadTexts()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy'
import NewTextModal from '@/views/s1mone/modals/NewTextModal.vue';
import RemoveTextModal from '@/views/s1mone/modals/RemoveTextModal';
import EditTextModal from '@/views/s1mone/modals/EditTextModal.vue';

export default {
    mixins: [setLocale],
    components: {
        NewTextModal,
        RemoveTextModal,
        EditTextModal
    },
    data() {
        return {
            searchTerm: '',
            searchSuggestions: [],
            texts: [],
            textsShown: [],
            searchActive: false,
            textSearchType: '',
            removeText: {},
            newTextModalOpen: false,
            RemoveTextModalOpen: false,
            EditTextModalOpen: false,
            editText: {},
        }
    },
    created() {
        document.title = 'S1MONE | Texts';
        this.loadTexts();
    },
    methods: {
        loadTexts() {
            let self = this;
            this.newTextModalOpen = false;
            this.RemoveTextModalOpen = false;
            this.EditTextModalOpen = false;
            fetch('/api/v1/text_language?')
            .then(response => { response.json().then(
                function(data) {
                    self.texts = data.data;
                    self.textsShown = data.data;
                });
            });
        },
        loadManagers(){
            let self = this;
            fetch('/api/v1/buildingmanager?')
            .then(response => { response.json().then(
                function(data) {
                    self.managers = data.data;
                });
            });
        },
        loadIndustries() {
            let self = this;
            fetch('/api/v1/industry?')
            .then(response => { response.json().then(
                function(data) {
                    self.industries = data.data;
                });
            });
        },
        getManagerName(id){
            const manager = this.managers.find(m => m.id_building_manager === id);
            return manager ? manager.name : '';
        },
        getIndustryName(id){
            const industry = this.industries.find(i => i.id_industry === id);
            return industry ? industry.display_name : '';
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.textsShown.length; i++) {
                    let texts = this.textsShown[i];
                    let name = texts.name.toLowerCase();
                    let description = texts.description? texts.description.toLowerCase() : '';
                    let nl = texts.nl ? texts.nl.toLowerCase() : '';
                    let en = texts.en ? texts.en.toLowerCase() : '';
                    if (name.includes(searchTerm) || description.includes(searchTerm) || nl.includes(searchTerm) || en.includes(searchTerm)) {
                        this.searchSuggestions.push(texts);
                    }
                }
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
        },
        sortTable(sorter) {
            this.textSearchType = sorter;
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.textsShown = sortBy(this.textsShown, sorter);
            if (this.sortOrder === 'desc') {
                this.textsShown.reverse();
            }
        }
        // selectasset(id) {
        //     this.$router.push('/s1mone/buildingmanager-detail?id=' + id);
        // }
    }
}
</script>
