<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners" >
                            <section class="incident-head">
                                <h1>Nieuws</h1>
                                <span class="edit-button icon" @click="newNewsModalOpen = true"><i class="fa-solid fa-plus"></i></span>
                            </section>
                            <br />
                            <div style="overflow: auto; height: 800px;">
                                <div v-for="item in news" :key="item.id_news_message" class="list-item" @click="item.edit = true">
                                    <div v-if="!item.edit">
                                        <div class="row">
                                            <div class="col">
                                                <span style="float: right; font-size: 13px; color: gray; margin-top: 25px;">{{ item.date }}</span>
                                                <b style="float: left;">{{ item.title }}</b>
                                                <div class="active-label" style="display: block; background: black; padding: 2px 4px 0 4px; top: 0;">{{ item.type }}</div>
                                            </div>
                                        </div> 
                                        <div class="row">
                                            <div class="col">
                                                <p v-html="item.body" style="margin-bottom: 0;" class="news-item"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="row">
                                            <div class="col">                   
                                                <span class="button button--small" @click="loadNews()" style="margin: 0 0 0 0; float: right; border-radius: 8px; background: #959191;">
                                                    Annuleren
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="form-field" :class="{focus:item.title.length}">
                                                    <label>Titel</label>
                                                    <input type="text" class="form-control" v-model="item.title">
                                                </div>
                                            </div>
                                        </div> 
                                        <br />                                      
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label><b>Type</b></label>
                                                <select class="form-select" v-model="item.type">
                                                    <option :value="'RELEASE'">RELEASE</option>
                                                    <option :value="'WARNING'">WARNING</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label><b>Rol</b></label>
                                                <select class="form-select" v-model="item.for_role">
                                                    <option :value="'ALL'">ALL</option>
                                                    <option :value="'BUILDINGMANAGER'">BUILDINGMANAGER</option>
                                                    <option :value="'MECHANIC'">MECHANIC</option>
                                                    <option :value="'REPAIRCOMPANY'">REPAIRCOMPANY</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-field" :class="{focus:item.body.length}">
                                            <label>Tekst</label>
                                            <textarea class="form-control" v-model="item.body"></textarea>
                                        </div>
                                        <div class="row">
                                            <div class="col">                   
                                                <span class="button button--small" @click="saveNews(item)" style="margin: 12px 0 0 0; float: right; border-radius: 8px;">
                                                    Opslaan
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>    
        </div>
        <!-- <NewNewsModal v-if="newNewsModalOpen" @close="loadNews()" /> -->
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
// import NewNewsModal from "@/components/S1MONE/modals/NewNewsModal.vue";
import sortBy from 'lodash/sortBy';
import moment from 'moment';

export default {
    mixins: [setLocale],
    components: {
        // NewNewsModal
    },
    data() {
        return {
            newNewsModalOpen: false,
            news: []
        }
    },
    created() {
        document.title = 'S1MONE | Beheerders';
        this.loadNews();
    },
    methods: {
        loadNews() {
            this.news = [];
            this.newNewsModalOpen = false;
            let self = this;
            fetch('/api/v1/newsmessage')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i].date = moment(data.data[i].created).locale('nl').format('D MMM YYYY HH:mm:ss');
                        data.data[i].edit = false;
                        self.news.push(data.data[i]);
                    }   
                    self.news = sortBy(self.news, 'created');  
                    self.news.reverse();   
                });
            });
        },
        saveNews(item) {
            let self = this;
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[' + item.id_news_message + '][title]', item.title);
            params.append('data[' + item.id_news_message + '][body]', item.body);
            params.append('data[' + item.id_news_message + '][type]', item.type);
            params.append('data[' + item.id_news_message + '][for_role]', item.for_role);
            fetch('/api/v1/newsmessage', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.loadNews();
            });
        }
    }
}
</script>
